import React, { useEffect } from 'react'
import { useState} from 'react';
import '../Styles/Navbar.css'
import '../App.css'
import Logo from '../Assets/Logo.png'
import { useNavigate } from 'react-router-dom';

function Navbar({page}) {

    const [activeItem, setActiveItem] = useState();

    let ss_nav = sessionStorage.getItem('activeNav')
    useEffect(() => {
        if(ss_nav){
            let x = sessionStorage.getItem('activeNav')
            setActiveItem(x)
        }
    }, [ss_nav])

    let navigateTo = useNavigate()
        
    const handleItemClick = (itemName) => {
        // console.log(itemName);

        if(itemName === 'home'){
            navigateTo("/")
            setActiveItem("home")
            sessionStorage.setItem('activeNav', 'home')
        }
        else if(itemName === 'advertising' || itemName === 'consulting' || itemName === 'finance'){
            setActiveItem('services')
            navigateTo('/services/'+itemName)
            sessionStorage.setItem('activeNav', 'services')
        }
        else if(itemName === 'simts' || itemName === 'consumerscience' || itemName === 'b2bscience' || itemName === 'publications'){
            setActiveItem('research')
            navigateTo('/research/'+itemName)
            sessionStorage.setItem('activeNav', 'research')
        }else{
            setActiveItem(itemName)
            sessionStorage.setItem('activeNav', itemName)
            navigateTo("/"+itemName)
        }
        page(itemName)
        document.querySelector('.nav-toggler-list').style.top = '-650px'
        document.querySelector('.nav-right-toggler').classList.remove('active')
        document.querySelector('.nav-right-toggler').innerHTML = `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"></path></svg>`
        document.querySelector('.nav-ext-bg').style.display = 'none'
        document.querySelectorAll('.nav-ext').forEach((y) => {
            y.style.display='none'
        })
        document.querySelectorAll('.nav-tog').forEach((x) => {
            x.style.display='none'
        })
      };
    

    function showNavExt(event) {
        let navText = event.target.innerText
        // console.log(navText);

        if(navText === "Services"){
            document.querySelectorAll('.nav-ext').forEach(x => {
                x.style.display = 'none'
            })
            document.getElementById("ext-services").style.display="flex";
        }else if(navText === "Research"){
            document.querySelectorAll('.nav-ext').forEach(x => {
                x.style.display = 'none'
            })
            document.getElementById("ext-research").style.display="flex";
    
        }else{
            document.querySelectorAll('.nav-ext').forEach(x => {
                x.style.display = 'none'
            })
        }
    }

    const handleMouseOut = (event) => {
      if(!event.relatedTarget || !event.currentTarget.contains(event.relatedTarget)){
        // console.log('Mouse left the div');
        document.querySelectorAll('.nav-ext').forEach(x => {
            x.style.display = 'none'
        })
      }
    }

    function extendNavToggler(event){
        let navItemClicked = event.currentTarget.innerHTML
        // console.log(navItemClicked);
           if(navItemClicked === "Services"){
            document.querySelector('.tog-serv').style.display = 'flex'
            document.querySelector('.tog-resr').style.display = 'none'
        }else if(navItemClicked === "Research"){
            document.querySelector('.tog-resr').style.display = 'flex'
            document.querySelector('.tog-serv').style.display = 'none'
        }else{
            document.querySelector('.tog-resr').style.display = 'none'
            document.querySelector('.tog-serv').style.display = 'none'
        }
    }

    function extendNavbar(event){
        let x = event.target.className
        // console.log(x);

        if(x === 'nav-right-toggler'){
            document.querySelector('.navbar').style.boxShadow = 'none'
            document.querySelector('.nav-toggler-list').style.top = '0'
            window.scrollTo(0,0)
            document.querySelector('.nav-right-toggler').classList.add('active')
            document.querySelector('.nav-ext-bg').style.display = 'block'
            document.querySelector('.nav-right-toggler').innerHTML = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>`
            
        }else if(x === 'nav-right-toggler active'){
            document.querySelector('.nav-toggler-list').style.top = '-650px'
            document.querySelector('.nav-right-toggler').classList.remove('active')
            document.querySelector('.nav-ext-bg').style.display = 'none'
            document.querySelectorAll('.nav-tog').forEach((x) => {
                x.style.display='none'
            })
            document.querySelector('.nav-right-toggler').innerHTML = `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"></path></svg>`
            
            setTimeout(() => {
                document.querySelector('.navbar').style.boxShadow = '0 2px 20px black'
            },500)
        }

    }

  return (
        <div>
        <nav className='navbar' onMouseOut={handleMouseOut}>
            <div className="nav-left">
               <img onClick={() => handleItemClick('home')}src={Logo} alt="" />
            </div>
            <div className="nav-right">
                <ul>
                    <li><p className={activeItem === 'products'?'nav-list active':'nav-list'} id='prod' onClick={() => handleItemClick('products')}  onMouseOver={(event) => showNavExt(event)}>Products</p></li>
                    <li>
                        <p className={activeItem === 'services'?'nav-list active':'nav-list'} id='serv' onMouseOver={(event) => showNavExt(event)}>Services</p>
                        <div className='nav-ext' id='ext-services' style={{display:'none'}}>
                            <p onClick={() => handleItemClick('advertising')}>Advertising</p>
                            <p onClick={() => handleItemClick('consulting')}>Consulting</p>
                            <p onClick={() => handleItemClick('finance')}>Finance</p>
                        </div>
                    </li>
                    <li>
                        <p className={activeItem === 'research'?'nav-list active':'nav-list'} id='resr' onMouseOver={(event) => showNavExt(event)}>Research</p>
                        <div className='nav-ext' id='ext-research' style={{display:'none'}}>
                            <p onClick={() => handleItemClick('simts')}>SILT</p>
                            <p onClick={() => handleItemClick('consumerscience')}>Consumer Science</p>
                            <p onClick={() => handleItemClick('b2bscience')}>B2B Science</p>
                            <p onClick={() => handleItemClick('publications')}>Publications</p>
                        </div>
                    </li>
                    <li><p className={activeItem === 'training'?'nav-list active':'nav-list'} id='train' onClick={() => handleItemClick('training')}  onMouseOver={(event) => showNavExt(event)}>Training</p></li>
                    <li><p className={activeItem === 'investments'?'nav-list active':'nav-list'} id='invest' onClick={() => handleItemClick('investments')} onMouseOver={(event) => showNavExt(event)}>Investments</p></li>
                    <li><p className={activeItem === 'mission'?'nav-list active':'nav-list'} id='misn' onClick={() => handleItemClick('mission')}  onMouseOver={(event) => showNavExt(event)}>Mission</p></li>
                    <li><p className={activeItem === 'contact'?'nav-list active':'nav-list'} id='cont' onClick={() => handleItemClick('contact')}  onMouseOver={(event) => showNavExt(event)}>Contact</p></li>
                    <li><p className={activeItem === 'career'?'nav-list active':'nav-list'} id='career' onClick={() => handleItemClick('career')}  onMouseOver={(event) => showNavExt(event)}>Career</p></li>
                    <li><p className={activeItem === 'aboutus'?'nav-list active':'nav-list'} id='abtus' onClick={() => handleItemClick('aboutus')} onMouseOver={(event) => showNavExt(event)}>About Us</p></li>
                </ul>

            </div>
            <div className="nav-right-toggler" style={{cursor:'pointer'}} onClick={(event) => extendNavbar(event)}>
                    <svg style={{pointerEvents:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"></path></svg>
            </div>
        </nav>
        
        <div className="nav-ext-bg"> </div>
        <div className='nav-toggler-list'>
        <p className={activeItem === 'products'?'nav-toggler-list-item active':'nav-toggler-list-item'} id='prod' onClick={() => handleItemClick('products')}>Products</p>
            <p className={activeItem === 'services'?'nav-toggler-list-item active':'nav-toggler-list-item'} style={{cursor:'pointer'}} onClick={(event) => extendNavToggler(event)}>Services</p>
            <div className='nav-tog tog-serv'>
                <p id='adver' onClick={() => handleItemClick('advertising')}>Advertising</p>
                <p id='consult' onClick={() => handleItemClick('consulting')}>Consulting</p>
                <p id='finance' onClick={() => handleItemClick('finance')}>Finance</p>
            </div>
            <p className={activeItem === 'research'?'nav-toggler-list-item active':'nav-toggler-list-item'} style={{cursor:'pointer'}} onClick={(event) => extendNavToggler(event)}>Research</p>
            <div className='nav-tog tog-resr'>
                <p id='simts' onClick={() => handleItemClick('simts')}>SILT</p>
                <p id='consumersc' onClick={() => handleItemClick('consumerscience')}>Consumer Science</p>
                <p id='b2bsc' onClick={() => handleItemClick('b2bscience')}>B2B Science</p>
                <p id='publications' onClick={() => handleItemClick('publications')}>Publications</p>
            </div>
            <p className={activeItem === 'training'?'nav-toggler-list-item active':'nav-toggler-list-item'} id='train' onClick={() => handleItemClick('training')}>Training</p>
            <p className={activeItem === 'investments'?'nav-toggler-list-item active':'nav-toggler-list-item'} id='invest' onClick={() => handleItemClick('investments')}>Investments</p>
            <p className={activeItem === 'mission'?'nav-toggler-list-item active':'nav-toggler-list-item'} id='misn' onClick={() => handleItemClick('mission')}>Mission</p>
            <p className={activeItem === 'contact'?'nav-toggler-list-item active':'nav-toggler-list-item'} id='cont' onClick={() => handleItemClick('contact')}>Contact</p>
            <p className={activeItem === 'career'?'nav-toggler-list-item active':'nav-toggler-list-item'} id='career' onClick={() => handleItemClick('career')}>Career</p>
            <p className={activeItem === 'aboutus'?'nav-toggler-list-item active':'nav-toggler-list-item'} id='abtus' onClick={() => handleItemClick('aboutus')}>About Us</p>
        </div>

        </div>
  )
}

export default Navbar