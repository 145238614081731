import React, { useEffect } from 'react'
import '../Styles/Career.css'

const CareerList = [
  {
    name: 'Front End Developer',
    subtext: 'We carefully choose over candidates for employment using a simple set of principles as stated below. Every candidate should answer the following questions and submit their answers to us before being considered for employment\n• How will this job help you succeed in your life goals?\n• Can you showcase some instances that you helped as a team member to solve a problem?\n• Are you driven to succeed in life? Can you explain this by providing some career plans that you have put together?\n• How did you apply some of the principles that you learnt in real life problem solving?'
  },
  {
    name: 'UI/UX Designer',
    subtext: 'We carefully choose over candidates for employment using a simple set of principles as stated below. Every candidate should answer the following questions and submit their answers to us before being considered for employment\n• How will this job help you succeed in your life goals?\n• Can you showcase some instances that you helped as a team member to solve a problem?\n• Are you driven to succeed in life? Can you explain this by providing some career plans that you have put together?\n• How did you apply some of the principles that you learnt in real life problem solving?'
  },
  {
    name: 'AI Architect',
    subtext: 'We carefully choose over candidates for employment using a simple set of principles as stated below. Every candidate should answer the following questions and submit their answers to us before being considered for employment\n• How will this job help you succeed in your life goals?\n• Can you showcase some instances that you helped as a team member to solve a problem?\n• Are you driven to succeed in life? Can you explain this by providing some career plans that you have put together?\n• How did you apply some of the principles that you learnt in real life problem solving?'
  },
  {
    name: 'Data Scientist',
    subtext: 'We carefully choose over candidates for employment using a simple set of principles as stated below. Every candidate should answer the following questions and submit their answers to us before being considered for employment\n• How will this job help you succeed in your life goals?\n• Can you showcase some instances that you helped as a team member to solve a problem?\n• Are you driven to succeed in life? Can you explain this by providing some career plans that you have put together?\n• How did you apply some of the principles that you learnt in real life problem solving?'
  },
  {
    name: 'Data Integration Lead',
    subtext: 'We carefully choose over candidates for employment using a simple set of principles as stated below. Every candidate should answer the following questions and submit their answers to us before being considered for employment\n• How will this job help you succeed in your life goals?\n• Can you showcase some instances that you helped as a team member to solve a problem?\n• Are you driven to succeed in life? Can you explain this by providing some career plans that you have put together?\n• How did you apply some of the principles that you learnt in real life problem solving?'
  },
  {
    name: 'Marketing Analyst',
    subtext: 'We carefully choose over candidates for employment using a simple set of principles as stated below. Every candidate should answer the following questions and submit their answers to us before being considered for employment\n• How will this job help you succeed in your life goals?\n• Can you showcase some instances that you helped as a team member to solve a problem?\n• Are you driven to succeed in life? Can you explain this by providing some career plans that you have put together?\n• How did you apply some of the principles that you learnt in real life problem solving?'
  },
]

function Career() {

  useEffect(() => {
    window.scrollTo(0,0);

  },[])

    document.querySelectorAll('.nav-ext').forEach(x => {
        x.style.display = 'none'
    })

    function showCareerDescr(e){
      let targ = e.currentTarget
      let careerDescr = targ.parentNode.id
      targ.classList.toggle('show')
      if(targ.className === 'car-descr show'){
        targ.innerHTML = `<svg style={{pointerEvents:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>`
        document.getElementById('showCareer'+careerDescr).style.display='flex'
      }else{
        targ.innerHTML = `<svg style={{pointerEvent:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>`
        document.getElementById('showCareer'+careerDescr).style.display='none'
      }
    }
    
  return (
    <div className='career'>
    <div className="career-content">
      <h1 className="career-title">Career</h1>
      <div className="career-descr">
        {CareerList.map((i,k) => {
          return(
          <div key={k} className="career-item-container">
            <div id={i.name} className='career-item'>
              {i.subtext && 
                <div onClick={showCareerDescr} className='car-descr'>
                  <svg style={{pointerEvent:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>
                </div>
              }
              <p>{i.name}</p>
            </div>
            {i.subtext && 
            <p id={`showCareer${i.name}`} className="career-subtext">{i.subtext.split('\n').map((line, y) => {
              return <span key={y} >{line}<br/></span>
            })
            
            }</p>
            }
          </div>
          )
        })}

      </div>
    </div>
  </div>
  )
}

export default Career
