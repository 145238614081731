import React from 'react'
import { useEffect } from 'react'
import '../Styles/Aboutus.css'

function Aboutus() {
  
  useEffect(() => {
    window.scrollTo(0,0)
  },[])


  return (
    <div className='aboutus'>
      <div className="aboutus-content">
        <h1 className="aboutus-title">About Us</h1>
        <p className='aboutus-item'>
          We are a small lean team working towards the mission of the company to solve consumers and B2B problems in the form of Quality IT Products
          <br/><br/>
          Though we are a small team, we think big and are not afraid to take complex problems from the world
        </p>
      </div>
    </div>
  )
}

export default Aboutus