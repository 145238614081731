import React from 'react'
import { useEffect } from 'react'
import '../../Styles/Research.css'
import PubPdf1 from '../../Assets/publications/publication1.pdf'
import PubPdf2 from '../../Assets/publications/publication2.pdf'


export const Publications_List = [
  {
    id: 1,
    title: "Publication Title 1",
    subtitle: "Publication 1 Subtitle",
    synopsis: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maxime explicabo cupiditate doloribus facilis voluptas dolores natus sit modi enim quod architecto rem, praesentium excepturi quaerat? Quaerat repellat",
    pdf: PubPdf1,
  },
  {
    id: 2,
    title: "Publication Title 2",
    subtitle: "Publication 2 Subtitle",
    synopsis: "Publication 2 Synopsis Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maxime explicabo sit modi enim quod architecto rem, praesentium excepturi quaerat? Quaerat repellat",
    pdf: PubPdf2
  },
]

function Publications({pub, getPub}) {


  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  
  useEffect(() => {
    document.querySelectorAll('.publication-item').forEach(x => {
      x.classList.remove('active')
    })
    console.log("PI",pub);
    if(pub !== null ){
      let scrollHt = document.getElementById(`pub${pub}`).getBoundingClientRect().top
       console.log(scrollHt);
      
      window.scrollTo({
        top: scrollHt - 200,
        behavior: 'smooth',
      });
      document.getElementById(`pub${pub}`).classList.add('active')
      document.getElementById(`pub${pub}`).querySelector('button').innerHTML = 'Show Less';
    }
    getPub(null)

  },[pub, getPub])

  function showPublication(e){
    // e.preventDefault()
    let btn = e.target
    let parentDiv = e.target.parentNode.parentNode
  
    console.log(parentDiv);

    parentDiv.classList.toggle('active')

    if(parentDiv.className === 'publications-item active'){
      btn.innerHTML = 'Show Less'
    }else{
      btn.innerHTML = 'Show More'
    }
  }

  function openPdfInNewTab(e){
    let x = e.target.id.split('pdf')[1] - 1

    console.log(x);
    const pdfUrl = Publications_List[x].pdf;
    const link = document.createElement('a');
    link.href = pdfUrl;
    // link.download = 'Publication.pdf'; 
    link.target = '_blank'
    link.click();
  }


  return (
    <div className='publications'>
      <div className="publications-content">
        <h1 className="publications-title">Publications</h1>
        <div className="publications-descr">
          {Publications_List.map((i,k)=> {
            return (
              <div key={k} className='publications-item' id={`pub${i.id}`}>
                <div className="publications-item-container">
                    <p className='publications-item-title'>{i.title}</p> 
                    <p className='publications-item-subtitle'>{i.subtitle}</p> 
                    <button className='expand' onClick={showPublication}>Show More</button>
                </div>
                <div className='publications-item-descr'>
                  <p>{i.synopsis}</p>
                  {i.pdf && 
                    <button id={`pdf${i.id}`} className='viewPdf' onClick={openPdfInNewTab}>View PDF</button>
                  }
                </div>  
              </div>
            )
          })}

        </div>

      </div>
    </div>
  )
}

export default Publications