import React, { useEffect } from 'react'
import '../Styles/Training.css'

const TrainingList = [
  {
    name: 'AI',
    // subtext: 'Our training on a specific technology is purely based on a real world application or a problem. Our research clearly dictates the fact that the technology is just a tool not a solution to a problem. A technology is chosen based on the business problem Not the other way around. Trainings are structured in a way that a professional or a student understands how to apply the tools and technologies to provide a solution to consumer problem or a business to business operations problem.'
  },
  {
    name: 'IOT',

  },
  {
    name: 'Block Chain',

  },
  {
    name: 'Edge Computing',

  },
  {
    name: 'Quantum Computing',

  },
  {
    name: 'VR / IR',

  },
]

function Training() {

  useEffect(() => {
    window.scrollTo(0,0);

  },[])

    document.querySelectorAll('.nav-ext').forEach(x => {
        x.style.display = 'none'
    })

    function showTrainDescr(e){
      let targ = e.currentTarget
      let trainDescr = targ.parentNode.id
      targ.classList.toggle('show')
      if(targ.className === 'train-descr show'){
        targ.innerHTML = `<svg style={{pointerEvents:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>`
        document.getElementById('showTrain'+trainDescr).style.display='flex'
      }else{
        targ.innerHTML = `<svg style={{pointerEvent:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>`
        document.getElementById('showTrain'+trainDescr).style.display='none'
      }
    }
    
  return (
    <div className='training'>
    <div className="training-content">
      <h1 className="training-title">Training</h1>
      <p className='scSubtext'>Our training on a specific technology is purely based on a real world application or a problem. Our research clearly dictates the fact that the technology is just a tool not a solution to a problem. A technology is chosen based on the business problem Not the other way around. Trainings are structured in a way that a professional or a student understands how to apply the tools and technologies to provide a solution to consumer problem or a business to business operations problem.</p>
      <div className="training-descr">
        {TrainingList.map((i,k) => {
          return(
          <div key={k} className="training-item-container">
            <div id={i.name} className='training-item'>
              {i.subtext && 
                <div onClick={showTrainDescr} className='train-descr'>
                  <svg style={{pointerEvent:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>
                </div>
              }
              <p>{i.name}</p>
            </div>
            {i.subtext && 
            <p id={`showTrain${i.name}`} className="training-subtext">{i.subtext}</p>
            }
          </div>
          )
        })}

      </div>
    </div>
  </div>
  )
}

export default Training