import React from 'react'
import { useEffect } from 'react'
import '../Styles/Products.css'
import GajaLogo from '../Assets/products/gaja.png'
import VpoLogo from '../Assets/products/vpo.png'
import UbeeLogo from '../Assets/products/ubee.png'
import SubaLogo from '../Assets/products/suba.png'
import SiltLogo from '../Assets/products/silt.png'
import JambuLogo from '../Assets/products/jambu.png'

const AllProducts = [
  {
    name: "GAJA",
    logo: GajaLogo,
    description: "This is modern Intelligent Shopping application which addresses the needs of the consumer and adapts to their shopping habits. This Intelligent application has an inbuilt marketing function that also adapts to the shopping needs of the consumer. AI Tools and Machine learning tools has been used to develop a seamless shopping experience for the consumer."
  },
  {
    name: "SILT",
    logo: SiltLogo,
    description: "Develop research and data driven solutions for complex world problems. Our goal to perform adequate research and understand the problems of the customers both in the Consumer world and in the Business world. This research helps us tailor the solution address the specific problem with a permanent solution."
  },
  {
    name: "SUBATOMIC (SUBA)",
    logo: SubaLogo,
    description: "This will be a B2B application which has the intelligence to build a WBS for a single profile. The tasks that are broken down to the smallest level possible, will help building the resource planning in a more stringent way. Tasks in a single profile with common characteristics can be grouped together to form a new profile. In essence, a single new profile is built on sub tasks from multiple master profiles."
  },
  {
    name: "TASK MANAGER (JAMBU)",
    logo: JambuLogo,
    description: "JAMBU is a task management application, which breaks the Tasking capabilities of a user to the next level. This is an intelligent application, which uses a single common interface to connect all systems and tasks are classified and stored for a later Notification system to intelligently work on those tasks to create a valuable experience for the end users."
  },
  {
    name: "UBEE",
    logo: UbeeLogo,
    description: "It is a Digital Speaker Management system to manage a perfect hidden surround sound system. The surround sound system will bring the match the real experience of an orchestra to a single room. The experience of these Digital Speaker Management System is not only engaging  but it will also be an immersive experience that is second to none."
  },
  {
    name: "VPO - Office of the Future",
    logo: VpoLogo,
    description: "This is an Intelligent digital mail management system, which interacts with the users through prioritization and performs action based on the learnings of its users.  The Automatic mode works very seamlessly with the user to provide appropriate solutions for the end user. The intelligent notification system is built on the back end to communicate with the iOT devices of the user."
  },
]


function Products() {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  function showProdDescr(e){
    // e.prevenetDefault()
    let targ = e.currentTarget
    let prodDescr = targ.parentNode.id
    targ.classList.toggle('show')
    if(targ.className === 'prod-descr show'){
      targ.innerHTML = `<svg style={{pointerEvents:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>`
      document.getElementById('showDescr'+prodDescr).style.display='flex'
    }else{
      targ.innerHTML = `<svg style={{pointerEvent:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>`
      document.getElementById('showDescr'+prodDescr).style.display='none'
    }


  }


  return (
    <div className='products'>
      <div className="products-content">
        <h1 className="products-title">Products</h1>
        <div className="products-descr">
          {AllProducts.map((x,k) => {
            return(
            <div key={k} className='products-container' >
            <div id={x.name} className="products-item">
              <div className="prod-img-container">
                <img className='prod-img' src={x.logo} alt="" />
              </div>
              <p className="prod-name">{x.name}</p>
              {x.description && 
              <div onClick={showProdDescr} className='prod-descr'>
                <svg style={{pointerEvent:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>
              </div>
              }
            </div>
            {x.description && 
            <p id={`showDescr${x.name}`} className='showDescr'>{x.description}</p>
            }
            </div>
            )
          })}
   
        </div>
      </div>
    </div>
  )
}

export default Products