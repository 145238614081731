import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import '../Styles/Home.css'
import {Publications_List} from './Research/Publications'
import bgColor from '../Assets/bg-color.jpg'

function Home({showPub}) {

  let [homeAnimate, setHomeAnimate] = useState(true)

  // console.log(Publications_List);

  useEffect(() => {
    window.scrollTo(0,0);
    document.body.style.backgroundImage = `url(${bgColor})`;
    document.querySelectorAll('.nav-list').forEach(x => {
      x.classList.remove('active')
    })
    sessionStorage.setItem('theme', 'home')

  },[])

  useEffect(() => {
    let timeouts = [];
    let intervals = []
  
    const line1Timeout = setTimeout(() => {
      document.getElementById('line1').style.animation = 'fade 1.5s ease forwards';
    }, 500);
    timeouts.push(line1Timeout);
  
    const line2Timeout = setTimeout(() => {
      document.getElementById('line2').style.animation = 'fade 1.5s ease forwards';
    }, 3500);
    timeouts.push(line2Timeout);
  
    const line3Timeout = setTimeout(() => {
      document.getElementById('line3').style.animation = 'fade 1.5s ease forwards';
    }, 6500);
    timeouts.push(line3Timeout);
  
    const lineTimeout = setTimeout(() => {
      document.querySelectorAll('.line').forEach(x => {
        x.style.animation = 'fadeRev 1s ease';
      });
    }, 9500);
    timeouts.push(lineTimeout);
  
    const hideTimeout = setTimeout(() => {
      document.querySelector('.initial-content').style.display = 'none';
    }, 12500);
    timeouts.push(hideTimeout);


    const initialArray = [];
    Publications_List.forEach(x => {
      initialArray.push(x.id);
    })
    
    const resrDisp = setTimeout(() => {
      
      const shuffledArray = [...initialArray].sort(() => Math.floor(Math.random() * Publications_List.length) - Math.floor(Math.random() * Publications_List.length));
      // console.log("Shuffled", shuffledArray);

      let i=0
      const intervalId = setInterval(() => {
        // console.log(i);

        document.querySelectorAll('.resr-display').forEach(x => {
          x.style.zIndex = "1"
        })

        let currID = `.resr-display${shuffledArray[i]}`
        document.querySelector(currID).style.display = 'flex'
        document.querySelector(currID).style.zIndex = "2"

        const mediaQ = window.matchMedia('(max-width: 768px) and (orientation: portrait)');
        const mediaQ2 = window.matchMedia('(max-device-width: 480px) and (orientation: landscape)');

        if(mediaQ.matches){
          document.querySelector(currID).style.animation = 'fadeSidePhone 1s ease forwards'
        }
        else if(mediaQ2.matches){
          document.querySelector(currID).style.animation = 'fadeSidePhone2 1s ease forwards'
        }
        else{
          document.querySelector(currID).style.animation = 'fadeSide 1s ease forwards'
        }
        
        if(i === shuffledArray.length-1){
          i=0
        }else{
          i = i+1
        }

        const showPubl = setTimeout(()=>{
          if(mediaQ.matches){
            document.querySelector(currID).style.animation = 'fadeSideOutPhone 1s ease forwards'
          }
          else if(mediaQ2.matches){
            document.querySelector(currID).style.animation = 'fadeSideOutPhone2 1s ease forwards'
          }
          else{
            document.querySelector(currID).style.animation = 'fadeSideOut 1s ease forwards'
          }
        },9500)
        timeouts.push(showPubl)

      }, 10000);
      intervals.push(intervalId);

    }, 1000);
    timeouts.push(resrDisp);


  
    if (!homeAnimate) {
      timeouts.forEach(timeout => clearTimeout(timeout));
      intervals.forEach(interval => clearInterval(interval));
      setHomeAnimate(false)
    }
    
    return () => {
      timeouts.forEach(timeout => clearTimeout(timeout));
      intervals.forEach(interval => clearInterval(interval));
    };
  }, [homeAnimate]);


  let navPub = useNavigate()

  function showResr(x) {
    showPub(x)
    navPub('/research/publications')
  }


  return (
          <div className='home'>
              
              <div className="initial-content">
                <div id='line1' className="line">
                  <div className="line-title">INPUTS</div>
                  <div className="line-text">Consumer and Business Problems</div>
                </div>
                <div id='line2' className="line">
                  <div className="line-title">OUR APPROACH</div>
                  <div className="line-text">Scientific, Data and Technology Driven, Linear, Lean, Creative and Critical Thinking</div>
                </div>
                <div id='line3' className="line">
                  <div className="line-title">OUTPUT</div>
                  <div className="line-text">Simple Linear and a Sensible Solution</div>
                </div>
              </div>

              {
                Publications_List.map((i,k) => {
                  return(
                    <div key={k} id={i.id} className={`resr-display resr-display${i.id}`}>
                      <h1 className="resr-title">{i.title}</h1>
                      <h3 className="resr-subtitle">{i.subtitle}</h3>
                      <button className='resr-btn' onClick={() => showResr(i.id)}>View Publication</button>
                    </div>
                  )
                })
              }
    
          </div>
      )
}

export default Home