import React from 'react'
import { useEffect } from 'react'
import '../../Styles/Research.css'

const B2bScList = [
  {
    name: 'Operational Management',
    // subtext: 'Operational Managementis the subtext'
  },
  {
    name: 'Innovation Management',

  },
  {
    name: 'Licensing Management',
  },
  {
    name: 'B2B Product Technologies',

  },
  {
    name: 'B2B Process Integration and Automation',
  },
  {
    name: 'B2B Intelligent Systems',

  },
  {
    name: 'B2B Business Process and Data Management',
  },
]

function B2bScience() {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  function showB2bSc(e){
    let targ = e.currentTarget
    let b2bScDescr = targ.parentNode.id
    targ.classList.toggle('show')
    if(targ.className === 'b2bSc-descr show'){
      targ.innerHTML = `<svg style={{pointerEvents:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>`
      document.getElementById('showB2bSc'+b2bScDescr).style.display='flex'
    }else{
      targ.innerHTML = `<svg style={{pointerEvent:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>`
      document.getElementById('showB2bSc'+b2bScDescr).style.display='none'
    }
  }


  return (
    <div className='b2bScience'>
      <div className="b2bScience-content">
        <h1 className="b2bScience-title">B2B Science</h1>
        <p className='scSubtext'>When compared to a consumer a business to business customer it's a little different. A business to business customer need to be understood from the markets that they serve. There is a greater need to know how that market is served through their products and services, how that business strategy is aligned with the products and services they offer. In conclusion there should be a perfect alignment of the business' goals and objectives to that of product market strategies.
        </p>
        <div className="b2bScience-descr">
        {B2bScList.map((i,k) => {
          return(
          <div key={k} className="b2bSc-item-container">
            <div id={i.name} className='b2bScience-item'>
              {i.subtext && 
                <div onClick={showB2bSc} className='b2bSc-descr'>
                  <svg style={{pointerEvent:'none'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path></svg>
                </div>
              }
              <p>{i.name}</p>
            </div>
            {i.subtext && 
            <p id={`showB2bSc${i.name}`} className="b2bSc-subtext">{i.subtext}</p>
            }
          </div>
          )
        })}
        </div>
      </div>
    </div>
  )
}

export default B2bScience