import React from 'react'
import { useEffect } from 'react'
import '../Styles/Mission.css'

function Mission() {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])


  return (
    <div className='mission'>
      <div className="mission-content">
        <h1 className="mission-title">Mission</h1>
        <div className="mission-descr">
            <p className='mission-item'>Solving high magnitude problems</p>
            <p className='mission-item'>Research and Data driven</p>
            <p className='mission-item'>Customized</p>
            <p className='mission-item'>Flexible, adaptable and reusable</p>
            <p className='mission-item'>Add high value and being useful</p>
            <p className='mission-item'>Simple design and delivery mechanisms</p>
        </div>
      </div>
    </div>
  )
}

export default Mission